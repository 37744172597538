import axios from 'axios'
import hapusStorage from '@/helper/hapusLocalStore'
import { mapState } from 'vuex'

export const pemerintahDaerahMixin = {
    mixins: [hapusStorage],
    data () {
        return {
            detailKlasifikasiMixin: [],
            detailUrusanMixin: [],
            detailPerangkatMixin: []
        }
    },
    computed: {
        ...mapState(['PemerintahDaerah'])
    },
    methods: {
        // data detail klasifikasi pd
        getDetailKlasifikasi (id) {
            axios.get("/api/pemerintah-daerah/klasifikasi-pd/" + id).then(response => {
                this.detailKlasifikasiMixin = response.data.data
                if (this.$route.name === 'Klasifikasi') {
                    this.form.fill(this.detailKlasifikasiMixin)
                    const kodeArr = this.form.kode.split('.')
                    this.kode.urusan = kodeArr[0]
                    this.kode.klasifikasi = kodeArr[1]
                } else {
                    this.kode.klasifikasi = this.detailKlasifikasiMixin
                }
            }).catch(error => {
                if (error.response.status === 401) {
                    // hapus Storage mixins
                    this.clearAll()
                }
            })
        },
        // data detail urusan pd
        getDetailUrusan (id) {
            axios.get("/api/pemerintah-daerah/urusan-pd/" + id).then(response => {
                this.detailUrusanMixin = response.data.data
                if (this.$route.name === 'Urusan') {
                    this.form.fill(this.detailUrusanMixin)
                } else {
                    this.kode.urusan = this.detailUrusanMixin
                }
            }).catch(error => {
                if (error.response.status === 401) {
                    // hapus storage mixins
                    this.clearAll()
                }
            })
        },
        // data detail perangkat pd
        getDetailPerangkat (id) {
            axios.get('/api/pemerintah-daerah/perangkat-pd/' + id).then(response => {
                this.detailPerangkatMixin = response.data.data
                this.form.fill(this.detailPerangkatMixin)
                this.form.urusan_pd_id = this.detailPerangkatMixin.klasifikasi_pd.urusan_pd_id
                this.getDetailUrusan(this.form.urusan_pd_id)
                this.$store.dispatch("PemerintahDaerah/getKlasifikasi", this.form.urusan_pd_id)
                this.getDetailKlasifikasi(this.form.klasifikasi_pd_id)
                const kodeArr = this.form.kode.split('.')
                this.kode.kode = kodeArr[0] + '.' + kodeArr[1]
                this.kode.perangkat = kodeArr[2]
            }).catch(error => {
                if (error.response.status === 401) {
                    //hapus storage mixins
                    this.clearAll()
                }
            })
        }
    }
}

export default pemerintahDaerahMixin